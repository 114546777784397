<template>
  <div>
    <app-bar
      ref="refAppBar"
      @reload="reload"
      :shop_site_id="props.shop_site_id"
      :content_format_ids="props.content_format_ids"
    ></app-bar>
    <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
      テンプレートのフォーマットを切り替えた際、切り替え前と後で同じ項目（タイトル、内容、画像等）がある場合は入力内容が保持されます。
    </div>
    <ranking-deli-message-component
      :show_flg="!props.shop_site_id && rankingDeliMessageFlg"
    ></ranking-deli-message-component>
    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="（キーワード検索）"
          single-line
          hide-details
          clearable
          dense
        ></v-text-field>
      </v-card-title>
      <!-- テーブル -->
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        :mobile-breakpoint="0"
        :items-per-page="-1"
        :footer-props="{
          'items-per-page-options': [50, 100, -1],
          'items-per-page-text': '表示件数',
        }"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <v-icon class="pa-1" @click="editItem(item)"> mdi-pencil </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.post="{ item }">
          <v-icon class="pa-1" @click="postItem(item)"> mdi-note-edit </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.image="{ item }">
          <div class="pa-1">
            <v-img
              width="50"
              height="50"
              :src="setting.cdn + item.image"
              v-if="item.select_tab === 0"
            ></v-img>
            <v-img
              width="50"
              height="50"
              v-else
              src="@/assets/images/cast_select.jpg"
            ></v-img>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.copy="{ item }">
          <v-icon class="pa-1" @click="copyItem(item)">
            mdi-content-copy
          </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.delete="{ item }">
          <v-icon class="pa-1" @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.sort="{ item, index }">
          <v-icon
            class="pa-1"
            @click="sortItem('up', item)"
            :disabled="index === 0"
          >
            mdi-chevron-up
          </v-icon>
          <v-icon
            class="pa-1"
            @click="sortItem('down', item)"
            :disabled="index === desserts.length - 1"
          >
            mdi-chevron-down
          </v-icon>
        </template>
        <template v-slot:no-data> 表示するデータがありません </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  defineComponent,
  onMounted,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilFunc from "@/common/utilFunc.js";
import appBar from "./appBar.vue";
import router from "@/router/index";
import retryRequest from "@/common/retryRequest";
import rankingDeliMessageComponent from "@/component/rankingDeliMessage.vue";

export default defineComponent({
  components: {
    appBar,
    rankingDeliMessageComponent,
  },
  props: ["shop_site_id", "content_format_ids"],
  setup(props, ctx) {
    const TemplateRepository = repositoryFactory.get("templates");
    const ShopSitesRepository = repositoryFactory.get("shopSites");

    const refAppBar = ref();
    const state = reactive({
      search: "",
      headers: [
        {
          text: "編集",
          value: "edit",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "すぐ投稿",
          value: "post",
          sortable: false,
          align: "center",
          width: 60,
        },
        { text: "画像", value: "image", sortable: false, width: 66 },
        {
          text: "テンプレート名",
          value: "name",
          sortable: true,
          class: "td_template_name",
        },
        {
          text: "投稿先Xアカウント",
          value: "x_account_name",
          sortable: true,
          class: "td_x_account_name",
          visible: false,
        },
        {
          text: "フォーマット",
          value: "content_format_name",
          sortable: true,
          class: "td_content_format_name",
        },
        {
          text: "複製",
          value: "copy",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "並び順",
          value: "sort",
          sortable: false,
          align: "center",
          width: 80,
          class: "td_sort",
        },
        {
          text: "削除",
          value: "delete",
          sortable: false,
          align: "center",
          width: 60,
        },
      ],
      desserts: [], // DataTablesレコード格納用
      rankingDeliMessageFlg: false,
    });

    const editItem = (template) => {
      // ダイアログ開く
      refAppBar.value.action({
        action: "edit",
        value: template.id,
      });
    };

    const postItem = async (item) => {
      // 手動で投稿
      refAppBar.value.action({
        action: "manual_post",
        value: item.id,
      });
    };

    const copyItem = async (template) => {
      if (window.confirm("テンプレートを複製してよろしいですか？")) {
        // コピー処理
        store.dispatch("loadingIcon/showIcon"); // ローディング表示
        const params = {
          id: template.id,
        };
        await TemplateRepository.copy(params)
          .then((response) => {
            if (response.data) {
              // データ追加OK
              const config = JSON.parse(response.data.config);
              state.desserts.push({
                id: response.data.id,
                name: response.data.name,
                image: config["image_0"],
                content_format_id: response.data.content_format_id,
                content_format_name: template.content_format_name,
                shop_site_id: response.data.shop_site_id,
                sort: response.data.sort,
              });
            }
          })
          .catch((error) => {
            throw (
              "ERROR:dialogComponent.vue/onSubmit templateRepository.create (" +
              error +
              ")"
            );
          });
      }

      await setDesserts();

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    // データ削除
    const deleteItem = async (item) => {
      if (
        window.confirm(
          "削除してよろしいですか？ ※このテンプレートを使用したコンテンツがあった場合、更新に失敗します。"
        )
      ) {
        // 削除処理
        store.dispatch("loadingIcon/showIcon"); // ローディング表示

        // 更新
        const result = await TemplateRepository.delete(item.id)
          .then((response) => {
            // 更新OK
            return true;
          })
          .catch((error) => {
            throw "ERROR:TemplateRepository.delete (" + error + ")";
          })
          .finally(() => {
            store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
          });

        await init();
      }
    };

    const setDesserts = async () => {
      if (!props.shop_site_id) {
        // 通常
        const response = await retryRequest(
          TemplateRepository.list,
          store.getters["shops/currentShop"].id
        );
        if (response.data) {
          state.desserts.splice(0);
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].shop_site_id == null) {
              state.desserts.push(response.data[i]);
            }
          }
        }
        // headerの表示制御(通常表示しないヘッダーは消しておく)
        state.headers = state.headers.filter(
          (header) =>
            header.value !== "x_account_name" && header.value !== "post"
        );
      } else {
        // X連携用
        const params = {
          shop_id: store.getters["shops/currentShop"].id,
          shop_site_id: props.shop_site_id,
        };
        const response = await retryRequest(
          TemplateRepository.list_ssid,
          params
        );
        if (response.data) {
          state.desserts = response.data;
        }
      }
    };

    // 初期化処理(onMounted後に実行しないと表が正しく読み込まれない)
    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // 店舗チェック falseの場合ログアウトになる
      const currentShop = store.getters["shops/currentShop"];
      if (!(await utilFunc.checkShop(currentShop.id, router))) return;

      // 表にセット
      await setDesserts();

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    init();

    // 更新サイトの一覧を取得し、駅チカがあるかないか調べる
    const rankingDeliCheck = async () => {
      const siteIds = [];
      const params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      try {
        const response = await retryRequest(ShopSitesRepository.list, params);
        if (response.data) {
          Object.keys(response.data).forEach(function (key) {
            siteIds.push(response.data[key].site_id);
          });
        }
      } catch (error) {
        console.log("ERROR:rankingDeliCheck", error);
      }
      // 駅チカ！人気ランキングがsiteIdsにある場合、rankingDeliMessageFlgをtrueにする
      state.rankingDeliMessageFlg = siteIds.some(
        (value) =>
          setting.ranking_deli_site_id.find((id) => id == value) != null
      );
    };
    onMounted(() => {
      rankingDeliCheck();
    });

    // リロード
    const reload = () => {
      init();
    };

    const sortItem = async (type, template) => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // 全データの並び順を取得
      const sortList = state.desserts.map((item) => {
        return { id: item.id, sort: item.sort };
      });
      // 並び順変更
      if (type === "up") {
        // 上に移動
        const index = sortList.findIndex((item) => item.id === template.id);
        if (index > 0) {
          const tmp = sortList[index - 1];
          sortList[index - 1] = sortList[index];
          sortList[index] = tmp;
        }
      } else {
        // 下に移動
        const index = sortList.findIndex((item) => item.id === template.id);
        if (index < sortList.length - 1) {
          const tmp = sortList[index + 1];
          sortList[index + 1] = sortList[index];
          sortList[index] = tmp;
        }
      }
      // sortListのsortに1から番号を振り直す
      sortList.forEach((item, index) => {
        item.sort = index + 1;
      });

      // 並び順変更
      const params = {
        sort_list: sortList,
      };
      const response = await retryRequest(
        TemplateRepository.update_sort,
        params
      );
      if (response.data) {
        if (response.data.result) {
          // 並び順変更成功
          // 更新された並び順をstate.dessertsに反映
          state.desserts = sortList.map((item) => {
            const dessert = state.desserts.find((d) => d.id === item.id);
            return { ...dessert, sort: item.sort };
          });
        }
      }

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    // 返却オブジェクト定義
    return {
      refAppBar,
      props,
      setting,
      ...toRefs(state),
      editItem,
      copyItem,
      deleteItem,
      sortItem,
      init,
      reload,
      postItem,
    };
  },
});
</script>
<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_template_name {
  min-width: 260px;
}
::v-deep .td_x_account_name {
  min-width: 160px;
}
::v-deep .td_content_format_name {
  min-width: 160px;
  width: 260px;
  font-size: 0.75em;
}
</style>
