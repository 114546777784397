<template>
  <div class="mb-8">
    <h3 id="image_head">画像</h3>
    <div class="mt-2 mb-2">添付する画像タイプを選択してください。</div>
    <v-card tile elevation="0">
      <v-tabs v-model="refTabs" show-arrows>
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="tab in imgTabComponents"
          :key="'imgt' + tab.index"
          active-class="font-weight-bold"
        >
          <div>{{ tab.title }}</div>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="refTabs">
        <v-tab-item
          v-for="tab in imgTabComponents"
          :key="'imgb' + tab.index"
          :eager="true"
          class=""
        >
          <div class="">
            <component
              v-bind:is="tab.component"
              ref="refImgComponent"
              :template_config="template_config"
              :is_contain="is_contain"
            ></component>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
  onMounted,
} from "@vue/composition-api";
import imageComponent from "./image";
import castImageComponent from "./castImage";

export default defineComponent({
  components: {
    imageComponent,
    castImageComponent,
  },
  props: ["template_config", "is_contain"],
  setup(props, ctx) {
    const refTabs = ref();
    const state = reactive({
      imgTabComponents: [],
    });

    const img_tab_title = [
      "画像をアップロードして設定",
      "セラピストを選択して設定",
    ];

    const init = () => {
      state.imgTabComponents = [
        {
          index: 0,
          title: img_tab_title[0],
          component: "imageComponent",
        },
        {
          index: 1,
          title: img_tab_title[1],
          component: "castImageComponent",
        },
      ];

      if (props.template_config) {
        refTabs.value = props.template_config["select_tab"];
      } else {
        refTabs.value = 0;
      }
    };

    init();

    const checkValid = async () => {
      let isValid = true;
      // キャストを選択して設定の時だけ、チェックする
      if (refTabs.value === 1) {
        isValid = await ctx.refs.refImgComponent[1].checkValid();
      }
      return isValid;
    };

    const saveConfig = async () => {
      let config = {};
      config["select_tab"] = refTabs.value;

      for (let i = 0; i < state.imgTabComponents.length; i++) {
        const imgComponent = ctx.refs.refImgComponent[i];
        if (imgComponent) {
          const tmp = await imgComponent.saveConfig();
          config = { ...config, ...tmp };
        }
      }

      return config;
    };

    // 返却オブジェクト定義
    return {
      refTabs,
      ...toRefs(state),
      props,
      checkValid,
      saveConfig,
    };
  },
});
</script>

<style scoped></style>
