<template>
  <!-- テンプレート登録ダイアログ -->
  <div>
    <div class="" id="cast_select_com">
      <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
        セラピストの選択方法を指定してください。
      </div>
      <!-- <span class="text-subtitle-1 font-weight-bold white--text">
        セラピストの選択方法を指定してください。
      </span> -->
    </div>
    <div class="pa-2 pt-3">
      <ValidationObserver ref="observer">
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-select
                ref="refSelectType"
                v-model="select_type"
                name="update_type"
                :items="selectTypeItem"
                no-data-text="選び方"
                label="選び方"
                success
                outlined
                hint="セラピストの選び方を指定[必須項目]"
                persistent-hint
                :rules="require_rule"
                class="mt-2"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
        <div v-show="select_type == 1" class="mini_comment mb-2">
          【説明】<br />
          媒体に登録されている全セラピストからランダムに選択して、1枚目のプロフィール画像を使用します。
        </div>
        <div v-show="select_type == 2" class="mini_comment mb-2">
          【説明】<br />
          当日出勤予定のセラピストからランダムに選択して、1枚目のプロフィール画像を使用します。<br />
          投稿する時間に、出勤中又は出勤予定のセラピストがいない場合、全セラピストからランダムに選択します。尚、退勤済みのセラピストは選択されません。
        </div>
        <v-container fluid v-show="select_type == 3" id="cast_select_list">
          <!-- <v-row>
            <v-col cols="12" class="mini_comment">
              【説明】<br />
              下記一覧にてチェックしたセラピストからランダムに選択して、1枚目のプロフィール画像を使用します。
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="6" sm="8" class="">
              <p class="text-subtitle-2">
                ※ランダムにしたくない場合は、ひとりだけチェックしてください。
              </p>
            </v-col>
            <v-col cols="6" sm="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="（キーワード検索）"
                single-line
                hide-details
                clearable
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <!-- テーブル -->
              <v-data-table
                class="cast_table"
                :headers="headers"
                :items="desserts"
                :mobile-breakpoint="0"
                :search="search"
                item-key="id"
                show-select
                :single-select="false"
                v-model="selected"
                @click:row="rowClick"
                :items-per-page="10"
                :footer-props="{
                  'items-per-page-options': [10, 20, 50, 100, -1],
                  'items-per-page-text': '表示件数',
                }"
              >
                <template v-slot:no-data> 表示するデータがありません </template>
                <template v-slot:footer.page-text="props">
                  {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                    props.itemsLength
                  }}件
                </template>
              <template v-slot:item.cast_name="{ item }"> <!-- eslint-disable-line -->
                  <div>
                    <v-avatar class="ma-1 mr-3"
                      ><v-img :src="setting.cdn + item.image" /></v-avatar
                    >{{ item.cast_name }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
  onMounted,
  watch,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";

export default defineComponent({
  props: ["template_config", "is_contain"],
  setup(props, ctx) {
    const castsRepository = repositoryFactory.get("casts");

    const state = reactive({
      select_type: 1,
      search: "",
      headers: [
        {
          text: "セラピスト",
          value: "cast_name",
          sortable: true,
        },
      ],
      desserts: [], // DataTablesレコード格納用
      selected: [],
    });

    const selectTypeItem = [
      { value: 1, text: "全セラピストからランダムに選択する" },
      { value: 2, text: "当日出勤するセラピストからランダムに選択する" },
      { value: 3, text: "チェックしたセラピストからランダムに選択する" },
    ];

    const getCastList = async () => {
      // セラピスト検索用リスト生成
      state.desserts.splice(0);
      const params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      await castsRepository
        .list(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.desserts.push({
                id: response.data[key].id,
                cast_name: response.data[key].name,
                image: response.data[key].image,
              });
            });
          }
        })
        .catch((error) => {
          throw "ERROR:castsRepository.list (" + error + ")";
        });
    };

    const setData = async () => {
      // 各項目に値をセット
      const config = props.template_config;
      state.select_type = config.select_type ?? 1;
      if (state.select_type == 3) {
        state.desserts.forEach((item) => {
          if (config.select_catsts.includes(item.id)) {
            state.selected.push(item);
          }
        });
      }
    };

    const clearData = async () => {
      // セラピストリスト取得
      await getCastList();

      state.select_type = 1;
    };

    onMounted(async () => {
      // 初期化
      await clearData();
      // データセット
      if (props.template_config) {
        await setData(props.template_config);
      }
    });

    // 保存前のチェック処理
    const checkValid = async () => {
      let isValid = true;
      if (state.select_type === 3 && state.selected.length === 0) {
        alert("セラピストがひとりもチェックされていません");
        document.querySelector("#cast_select_list").scrollIntoView();
        isValid = false;
      }

      console.log("castImage", isValid);
      return isValid;
    };

    // 保存処理
    const saveConfig = async () => {
      // 保存処理
      const config = {};
      config["select_type"] = state.select_type;
      if (state.select_type == 3) {
        config["select_catsts"] = state.selected.map((item) => item.id);
      } else {
        config["select_catsts"] = [];
      }

      return config;
    };

    const rowClick = (item, row) => {
      row.select(!row.isSelected);
    };

    // 返却オブジェクト定義
    return {
      setting,
      selectTypeItem,
      ...toRefs(state),
      getCastList,
      checkValid,
      saveConfig,
      rowClick,
      require_rule: [(v) => !!v || "必須選択項目です"],
    };
  },
});
</script>

<style scoped>
.image_flame {
  overflow: auto;
}
.image_box {
  display: flex;
}
.image_card {
  margin-right: 0.25em;
  position: relative;
}
.image_drag_btn {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 2;
}
.mini_comment {
  padding: 0.5em;
  margin-bottom: 0.5em;
  border-radius: 0.5em;
  background-color: #eefaff;
}
</style>
