var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer"},[_c('h3',{attrs:{"id":"body_head"}},[_vm._v("投稿先アカウント")]),_c('div',{staticClass:"mt-2"},[_vm._v(" このテンプレートで投稿するXのアカウントを選択してください。 ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{ref:"refXAccountId",staticClass:"mt-2",attrs:{"name":"x_account_id","items":_vm.xAccountItems,"no-data-text":"連携しているアカウントがありません","label":"投稿先アカウント","success":"","outlined":"","hint":"投稿先のアカウント指定[必須項目]","persistent-hint":"","rules":_vm.require_rule,"readonly":_vm.props.shop_site_x_account_id != null},model:{value:(_vm.xAccountId),callback:function ($$v) {_vm.xAccountId=$$v},expression:"xAccountId"}})],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h3',{attrs:{"id":"body_head"}},[_vm._v("当日スケジュール投稿のコメント")]),_c('div',{staticClass:"mt-2"},[_vm._v(" スケジュール予定を投稿する際のコメントを設定します。"),_c('br'),_c('span',{class:_vm.tweetCount > 100 ? 'text-red' : ''},[_vm._v("先頭コメント＋末尾コメント: "+_vm._s(_vm.tweetCount)+" / 100文字まで可")])]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"先頭コメント","rules":{
                    x_post_length: { length: 100, cnt: _vm.tweetCount },
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var valid = ref.valid;
return [_c('v-textarea',{attrs:{"name":"body","error-messages":errors,"success":valid,"label":"先頭コメント","rows":"4","placeholder":"本日出勤のセラピストを紹介します。\nhttps://example.com/schedule","outlined":"","background-color":"white","hint":"半角文字:0.5文字、全角文字:1文字、改行:0.5文字、URL:11.5文字として扱います。","persistent-hint":""},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}})]}}])})],1)],1)],1),_c('div',{staticClass:"mt-2"},[_vm._v(" 先頭コメントと末尾コメントの間に、出勤セラピストの名前と出勤時間を表示します。 ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"mt-2",attrs:{"name":"schedule_type","items":_vm.scheduleTypeItems,"no-data-text":"表示タイプ","label":"表示タイプ","success":"","outlined":"","hint":"表示タイプの指定[必須項目]","persistent-hint":"","rules":_vm.require_rule},model:{value:(_vm.schedule_type),callback:function ($$v) {_vm.schedule_type=$$v},expression:"schedule_type"}})],1)],1)],1),_c('div',{staticClass:"mt-2"},[_vm._v(" 出勤セラピストの下に表示する末尾コメントを設定します。 ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"末尾コメント","rules":{ x_post_length: { length: 100, cnt: _vm.tweetCount } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var valid = ref.valid;
return [_c('v-textarea',{attrs:{"name":"end_body","error-messages":errors,"success":valid,"label":"末尾コメント","rows":"4","placeholder":"","outlined":"","background-color":"white","hint":"半角文字:0.5文字、全角文字:1文字、改行:0.5文字、URL:11.5文字として扱います。","persistent-hint":""},model:{value:(_vm.end_body),callback:function ($$v) {_vm.end_body=$$v},expression:"end_body"}})]}}])})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h3',[_vm._v("投稿イメージ")]),_c('div',{staticClass:"mt-2"},[_vm._v(" Xに投稿した時のイメージです。※退勤したセラピストおよび投稿文字数がオーバーする際は、セラピストを省略する場合があります。 ")]),_c('div',{staticClass:"schedule-type-sample",domProps:{"innerHTML":_vm._s(
              "" + (_vm.body ? _vm.body.replace(/\n/g, '<br />') + '<br /><br />' : '') +
              _vm.scheduleTypeSample[_vm.schedule_type] +
              "" + (_vm.end_body
                  ? '<br /><br />' + _vm.end_body.replace(/\n/g, '<br />')
                  : '')
            )}})])],1)],1),_c('tabImageComponent',{ref:"refTabImageComponent",attrs:{"template_config":_vm.template_config,"is_contain":true}}),_c('xPhotoCountComponent',{ref:"refXPhotoCountComponent",attrs:{"template_config":_vm.template_config}}),_c('xReplySettingsComponent',{ref:"refXReplySettingsComponent",attrs:{"template_config":_vm.template_config}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }